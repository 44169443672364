.thead th {
  background-color: var(--primaryColor) !important;
}
.customBtn {
  background-color: var(--accentColor);
}
/* You can customize these styles according to your design */
.dragButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--accentColor);
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bolder;
  background-color: #fff;
  cursor: grab;
  user-select: none;
}

/* Change the cursor style when the button is held */
.dragButton:active {
  cursor: grabbing;
}

/* Add some visual feedback on hover */
.dragButton:hover {
  background-color: #f0f0f0;
}

.categoryIcon {
  max-width: 200px;
  min-width: 150px;
  object-fit: contain;
}
.listGroup {
  transition: background-color 0.3s ease;
}
.listGroup:hover li {
  background-color: rgb(236, 236, 236);
}
.listItem {
  background-color: rgb(242, 242, 242);
  padding: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .table td,
  .table th {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .table-responsive {
    width: 100%;
  }
  .table thead tr {
    display: none;
  }
  .table td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
}
