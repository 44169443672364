@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
body {
  font-family:  "Times New Roman", sans-serif;
  font-weight: 400;
}

:root {
  /* --primaryColor: #010124;0 */
  --primaryColor: #010236;
  --primaryColor: #020469;
  --accentColor: #0077b6;
  --accentColorLight: #00b4d8;
}
